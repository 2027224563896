<template lang="pug">
  div
    .row
      .col-md-12
        .component-card
          v-btn.mr-3 Normal
          v-btn.mr-3(loading) Normal
          v-btn.mr-3(disabled) Disabled
          v-btn.mr-3(danger) Danger
          v-btn.mr-3(info) Danger

      .col-md-12
        .component-card
          .d-flex
            v-donut
            v-donut(
              :value="1")
            v-donut(
              :value="4"
              :count="10")
            v-donut(
              :value="2"
              :count="3")

      .col-md-12
        .component-card
          v-btn.mr-3
            i.fa.fa-plus.mr-2
            span Normal
          v-btn.mr-3(loading)
            i.fa.fa-plus.mr-2
            span Normal
          v-btn.mr-3(disabled)
            i.fa.fa-plus.mr-2
            span Disabled
          v-btn.mr-3(danger)
            i.fa.fa-minus.mr-2
            span Danger
          v-btn.mr-3(info)
            i.fa.fa-minus.mr-2
            span Danger

    .row
      .col-md-12
        .component-card
          v-btn.mr-3(text) Text
          v-btn.mr-3(text loading) Text
          v-btn(text disabled) Disabled
          v-btn.mr-3(text danger) Danger
          v-btn.mr-3(text info) Danger

      .col-md-12
        .component-card
          v-btn.mr-3(text)
            i.fa.fa-plus.mr-2
            span Text
          v-btn.mr-3(text loading)
            i.fa.fa-plus.mr-2
            span Text
          v-btn(text disabled)
            i.fa.fa-plus.mr-2
            span Disabled
          v-btn.mr-3(text danger)
            i.fa.fa-plus.mr-2
            span Text
          v-btn.mr-3(text info)
            i.fa.fa-plus.mr-2
            span Text

    .row
      .col-md-4
        .component-card
          v-btn.mr-3(icon)
            i.fa.fa-plus
          v-btn.mr-3(icon loading)
            i.fa.fa-plus
          v-btn.mr-3(icon disabled)
            i.fa.fa-plus
          v-btn.mr-3(icon danger)
            i.fa.fa-plus
          v-btn.mr-3(icon info)
            i.fa.fa-plus

      .col-md-4
        .component-card
          v-btn.mr-3(icon outline)
            i.fa.fa-plus
          v-btn.mr-3(icon outline loading)
            i.fa.fa-plus
          v-btn.mr-3(icon outline disabled)
            i.fa.fa-plus
          v-btn.mr-3(icon danger outline)
            i.fa.fa-plus
          v-btn.mr-3(icon info outline)
            i.fa.fa-plus

      .col-md-4
        .component-card
          v-btn.mr-3(fab)
            i.fa.fa-plus
          v-btn.mr-3(loading fab)
            i.fa.fa-plus
          v-btn.mr-3(fab disabled)
            i.fa.fa-plus
          v-btn.mr-3(fab danger)
            i.fa.fa-plus
          v-btn.mr-3(fab info)
            i.fa.fa-plus
      .col-md-12
        h2.mb-2 Small
        .component-card
          .element-item
            code v-btn(small)
            v-btn(small) Normal
          .element-item
            code v-btn(small loading)
            v-btn(small loading) Normal
          .element-item
            code v-btn(small disabled)
            v-btn(small disabled) Disabled
          .element-item
            code v-btn(small danger)
            v-btn(small danger) Danger
          .element-item
            code v-btn(small info)
            v-btn(small info) Danger

      .row
      .col-md-4
        .component-card
          v-btn.mr-3(small icon)
            i.fa.fa-plus
          v-btn.mr-3(small icon loading)
            i.fa.fa-plus
          v-btn.mr-3(small icon disabled)
            i.fa.fa-plus
          v-btn.mr-3(small icon danger)
            i.fa.fa-plus
          v-btn.mr-3(small icon info)
            i.fa.fa-plus

      .col-md-4
        .component-card
          v-btn.mr-3(small icon outline)
            i.fa.fa-plus
          v-btn.mr-3(small icon outline loading)
            i.fa.fa-plus
          v-btn.mr-3(small icon outline disabled)
            i.fa.fa-plus
          v-btn.mr-3(small icon danger outline)
            i.fa.fa-plus
          v-btn.mr-3(small icon info outline)
            i.fa.fa-plus

      .col-md-4
        .component-card
          v-btn.mr-3(small fab)
            i.fa.fa-plus
          v-btn.mr-3(small loading fab)
            i.fa.fa-plus
          v-btn.mr-3(small fab disabled)
            i.fa.fa-plus
          v-btn.mr-3(small fab danger)
            i.fa.fa-plus
          v-btn.mr-3(small fab info)
            i.fa.fa-plus

    .row
      .col-md-4
        .component-card
          v-dropdown-btn.mb-3.mr-3(small) Hello
            template(slot="dropdown")
              v-card(
                elevation
                overflow-hidden)
                v-list-item Hello
                v-list-item Bey

          v-dropdown-btn.mb-3 Hello
            template(slot="dropdown")
              v-card(
                elevation
                overflow-hidden)
                v-list-item Hello
                v-list-item Bey

          v-dropdown-btn(large block) Hello
            template(slot="dropdown")
              v-card(
                elevation
                overflow-hidden)
                v-list-item Hello
                v-list-item Bey

</template>

<script>
import VBtn from '@/components/ui/v-btn/VBtn'
import VDropdownBtn from '@/components/ui/v-dropdown-btn/VDropdownBtn'

export default {
  name: 'VBtnDemo',

  components: {
    VBtn,
    VDropdownBtn
  }
}
</script>

<style lang="pug">
</style>
